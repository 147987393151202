export const environment = {
    // Specifically used by PingAuthenticationModule in app.module.ts.
    authority: 'https://idptest.aa.com',
    clientId: 'cers-test',
    idle_threshold: 900,
    timeout_duration: 60,
    use_html5_routing: false,
    load_user_info: true,
    logLevel: 2,
    logoutURL: 'https://pfloginapp-nonprod.cloud.aa.com/api/palogout?TargetResource=https://cers-test.aa.com',
    // Outside of PingAuthenticationModule.
    apiUri: 'https://cersapi-test.aa.com/SSEHub.CERSPortal/2.0',
    encryptKey: '16D71B81C42E4FA7AC16B2BDEA7E53E9',
    production: false,
    serviceWorker: true,
    appUrl: 'https://cers-test.aa.com',
    loginUrl: 'https://cers-test.aa.com',
    ssoEndpoint: 'https://pfloginapp-nonprod.cloud.aa.com/',
    envName: 'test',
    userOverride: null,
    cognosUrl: 'https://cognos.dev.aa.com/cers-interactive',
    scope: 'openid profile offline_access',
};
